<template>
  <div class="user-data">
    <div class="user-data__avatar-container">
      <img
        class="user-data__avatar"
        :src="
          user.photo
            ? $root.host + user.photo
            : user.default_photo ||
              `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/content/cabinet/default-avatar.svg`
        "
      />
    </div>
    <div class="user-data__text">
      <div class="user-data__title">Мой AZ-MOST</div>
      <div class="user-data__name" v-if="user && Object.keys(user).length">
        {{ `${user.last_name} ${user.first_name[0]}.${user.middle_name[0] ? user.middle_name[0]+"." : ""}` }}
        <div v-if="user.verified" class="ml-2 custom-title custom-title_v-bottom custom-title_h-center" data-hint="Профиль верифицирован">
          <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="3" y="3" width="18" height="18" rx="9" fill="#830051" />
          <path
            d="M16 10L10.5 15L8 12.7273"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UserData",
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.user-data {
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e8e8e8;
  padding: 24px;

  &__avatar-container {
    width: 71px;
    height: 71px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 16px;
    background-color: #fff;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    padding: 15px;
  }

  &__title {
    margin-bottom: 8px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #830051;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1f1f1f;
  }
}
</style>